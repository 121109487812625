export default {
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  list(token, farmerId, params = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/campaigns`,
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  add(token, farmerId, data = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/campaigns`,
      method: 'POST',
      headers: {
        Authorization: token,
        ...headers,
      },
      data,
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {Number} campaignId
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  edit(token, farmerId, campaignId, data = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/campaigns/${campaignId}/`,
      method: 'PUT',
      headers: {
        Authorization: token,
        ...headers,
      },
      data,
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {Number} campaignId
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  delete(token, farmerId, campaignId, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/campaigns/${campaignId}/`,
      method: 'DELETE',
      headers: {
        Authorization: token,
        ...headers,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {Number} campaignId
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  stats(token, farmerId, campaignId, data = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/campaigns/${campaignId}/shipments/stats`,
      method: 'POST',
      headers: {
        Authorization: token,
        ...headers,
      },
      data,
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {Number} campaignId
   * @param {String} unit
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  progression(token, farmerId, campaignId, unit, data = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/campaigns/${campaignId}/shipments/progression`,
      method: 'POST',
      headers: {
        Authorization: token,
        ...headers,
      },
      data: Object.assign(data, {
        unit,
      }),
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
}

export const NAME_AUTH = 'auth'
export const NAME_INIT = 'auth-init'
export const NAME_SERVER = 'auth-server'
export const NAME_LOGIN = 'auth-login'
export const NAME_FORGOT_PASSWORD = 'auth-forgot-password'

export default [
  {
    path: '/auth',
    component: () => import('@/views/pages/authentication/Auth.vue'),
    meta: {
      layout: 'blank',
    },
    children: [
      {
        path: '',
        name: NAME_AUTH,
        redirect: () => ({ name: NAME_INIT }),
      },
      {
        path: '/init',
        name: NAME_INIT,
        meta: {
          redirectIfLoggedIn: true,
        },
        component: () => import('@/views/pages/authentication/Init.vue'),
      },
      {
        path: 'server',
        name: NAME_SERVER,
        meta: {
          redirectIfLoggedIn: true,
        },
        component: () => import('@/views/pages/authentication/Server.vue'),
      },
      {
        path: 'login',
        name: NAME_LOGIN,
        meta: {
          redirectIfLoggedIn: true,
        },
        component: () => import('@/views/pages/authentication/Login.vue'),
      },
      {
        path: 'forgot-password',
        name: NAME_FORGOT_PASSWORD,
        meta: {
          redirectIfLoggedIn: true,
        },
        component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
      },
    ],
  },
]

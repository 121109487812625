import Wrap from './Wrapper.vue'

function install(Vue) {
  if (install.installed) return
  install.installed = true
  Vue.component('app-view', Wrap)
}

const VueView = {
  install,
  Wrap,
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueView)
}

export default VueView

<template>
  <ScrollerOriginal
    ref="content"
    :on-refresh="onRefresh"
    :on-infinite="onInfinite"
  >
    <template #request>
      <span> {{ $t('therms.need-update') }} </span>
    </template>
    <template #confirmed>
      <span> {{ $t('therms.release-to-update') }} </span>
    </template>
    <template #top-loading>
      <b-spinner class="top-loader" />
    </template>
    <template #bottom-loading>
      <b-spinner class="bottom-loader" />
    </template>
    <template #no-data>
      <slot name="no-data">
        <p class="text-center text-primary m-0">
          <i class="fa-solid fa-circle-check text-primary mr-50" />
          À jour
        </p>
      </slot>
    </template>
    <div
      class="padding"
      :class="{'no-infinite': !onInfinite, 'no-refresh' : !onRefresh}"
    >
      <slot />
    </div>
  </ScrollerOriginal>
</template>

<style lang="scss" scoped>

.top-loader {
  position: relative;
  top: calc(env(safe-area-inset-top) + 5px);
}

.bottom-loader {
  position: relative;
  top: -6px;
}

.padding {
  padding: calc(env(safe-area-inset-top) + 15px) 25px 0 25px;

  &.no-infinite {
    padding-bottom: 1px;
  }

  &.no-infinite.no-refresh {
    padding-bottom: 60px;
  }
}
</style>

<script>
import { BSpinner } from 'bootstrap-vue'
import Scroller from './Scroller.vue'

export default {
  components: {
    ScrollerOriginal: Scroller,
    BSpinner,
  },
  props: {
    onRefresh: {
      type: [Function, Boolean],
      default: false,
    },

    onInfinite: {
      type: [Function, Boolean],
      default: false,
    },
  },
  computed: {},
  watch: {},
  methods: {},
}

</script>

<template>
  <div
    :id="containerId"
    class="_v-container"
    @touchstart="touchStart($event)"
    @touchmove="touchMove($event)"
    @touchend="touchEnd($event)"
    @mousedown="mouseDown($event)"
    @mousemove="mouseMove($event)"
    @mouseup="mouseUp($event)"
  >
    <div
      :id="contentId"
      class="_v-content"
    >
      <div
        v-if="onRefresh"
        class="pull-to-refresh-layer"
        :class="{'active': state === 1, 'active refreshing': state === 2}"
      >
        <div class="spinner-holder">
          <slot
            v-if="state !== 2 && state !== 1"
            name="request"
          />
          <slot
            v-if="state === 1"
            name="confirmed"
          />
          <slot
            v-if="state === 2"
            name="top-loading"
          >
            <slot
              name="loading"
            >
              Chargement ...
            </slot>
          </slot>
        </div>
      </div>

      <slot />

      <div
        v-if="showInfiniteLayer"
        class="loading-layer"
      >
        <div
          class="spinner-holder"
          :class="{'active': showLoading}"
        >
          <slot name="bottom-loading">
            <slot
              name="loading"
            >
              Chargement ...
            </slot>
          </slot>
        </div>

        <div
          class="no-data"
          :class="{'active': !showLoading && loadingState === 2}"
        >
          <slot name="no-data" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css" scoped>
._v-container {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

._v-container > ._v-content {
  width: 100%;
  -webkit-transform-origin: left top;
  -webkit-transform: translateZ(0);
  -moz-transform-origin: left top;
  -moz-transform: translateZ(0);
  -ms-transform-origin: left top;
  -ms-transform: translateZ(0);
  -o-transform-origin: left top;
  -o-transform: translateZ(0);
  transform-origin: left top;
  transform: translateZ(0);
}

._v-container > ._v-content > .pull-to-refresh-layer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 60px;
  margin-top: -60px;
  text-align: center;
}

._v-container > ._v-content > .loading-layer {
  width: 100%;
  height: 60px;
  line-height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

._v-container > ._v-content > .loading-layer > .no-data {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.15s linear;
  -webkit-transition: opacity 0.15s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}
._v-container > ._v-content > .loading-layer > .no-data.active {
  opacity: 1;
}

._v-container > ._v-content > .loading-layer > .spinner-holder {
  opacity: 0;
  transition: opacity 0.15s linear;
  -webkit-transition: opacity 0.15s linear;
}

._v-container > ._v-content > .loading-layer > .spinner-holder.active {
  opacity: 1;
}

</style>
<script>
/* eslint-disable */
import Scroller from './modules/core'
import getContentRender from './modules/render'

const re = /^[\d]+(\%)?$/

const widthAndHeightCoerce = v => {
  if (v[v.length - 1] !== '%') return `${v}px`
  return v
}

const widthAndHeightValidator = v => re.test(v)

export default {
  props: {
    onRefresh: {
      type: [Function, Boolean],
      default: false,
    },

    onInfinite: {
      type: [Function, Boolean],
      default: false,
    },

    width: {
      type: String,
      default: '100%',
      validator: widthAndHeightValidator,
    },

    height: {
      type: String,
      default: '100%',
      validator: widthAndHeightValidator,
    },

    snapping: {
      type: Boolean,
      default: false,
    },

    snapWidth: {
      type: Number,
      default: 100,
    },

    snapHeight: {
      type: Number,
      default: 100,
    },

    animating: {
      type: Boolean,
      default: true,
    },

    animationDuration: {
      type: Number,
      default: 250,
    },

    bouncing: {
      type: Boolean,
      default: true,
    },

    cssClass: String, // content css class

    minContentHeight: {
      type: Number,
      default: 0, // px
    },
  },

  data() {
    return {
      containerId: `outer-${Math.random()
        .toString(36)
        .substring(3, 8)}`,
      contentId: `inner-${Math.random()
        .toString(36)
        .substring(3, 8)}`,
      state: 0, // 0: pull to refresh, 1: release to refresh, 2: refreshing
      loadingState: 0, // 0: stop, 1: loading, 2: stopping loading

      showLoading: false,

      container: undefined,
      content: undefined,
      scroller: undefined,
      pullToRefreshLayer: undefined,
      mousedown: false,
      infiniteTimer: undefined,
      resizeTimer: undefined,
    }
  },

  computed: {
    w() {
      return widthAndHeightCoerce(this.width)
    },

    h() {
      return widthAndHeightCoerce(this.height)
    },

    showInfiniteLayer() {
      let contentHeight = 0
      this.content
        ? contentHeight = this.content.offsetHeight
        : void 666

      return this.onInfinite
        ? contentHeight > this.minContentHeight
        : false
    },
  },

  mounted() {
    this.container = document.getElementById(this.containerId)
    this.container.style.width = this.w
    this.container.style.height = this.h

    this.content = document.getElementById(this.contentId)
    if (this.cssClass) this.content.classList.add(this.cssClass)
    this.pullToRefreshLayer = this.content.getElementsByTagName('div')[0]

    const render = getContentRender(this.content)

    const scrollerOptions = {
      scrollingX: false,
    }

    this.scroller = new Scroller(render, {
      scrollingX: false,
      snapping: this.snapping,
      animating: this.animating,
      animationDuration: this.animationDuration,
      bouncing: this.bouncing,
    })

    // enable PullToRefresh
    if (this.onRefresh) {
      this.scroller.activatePullToRefresh(60, () => {
        this.state = 1
      }, () => {
        this.state = 0
      }, () => {
        this.state = 2

        this.$on('$finishPullToRefresh', () => {
          setTimeout(() => {
            this.state = 0
            this.finishPullToRefresh()
          })
        })

        this.onRefresh(this.finishPullToRefresh)
      })
    }

    // enable infinite loading
    if (this.onInfinite) {
      this.infiniteTimer = setInterval(() => {
        const {
          left,
          top,
          zoom,
        } = this.scroller.getValues()

        // 在 keep alive 中 deactivated 的组件长宽变为 0
        if (this.content.offsetHeight > 0
            && top + 60 > this.content.offsetHeight - this.container.clientHeight) {
          if (this.loadingState) return
          this.loadingState = 1
          this.showLoading = true
          this.onInfinite(this.finishInfinite)
        }
      }, 10)
    }

    // setup scroller
    const rect = this.container.getBoundingClientRect()
    this.scroller.setPosition(rect.left + this.container.clientLeft, rect.top + this.container.clientTop)

    // snapping
    if (this.snapping) {
      this.scroller.setSnapSize(this.snapWidth, this.snapHeight)
    }

    // onContentResize
    const contentSize = () => ({
      width: this.content.offsetWidth,
      height: this.content.offsetHeight,
    })

    let {
      content_width,
      content_height,
    } = contentSize()

    this.resizeTimer = setInterval(() => {
      const {
        width,
        height,
      } = contentSize()
      if (width !== content_width || height !== content_height) {
        content_width = width
        content_height = height
        this.resize()
      }
    }, 10)
  },

  destroyed() {
    clearInterval(this.resizeTimer)
    if (this.infiniteTimer) clearInterval(this.infiniteTimer)
  },

  methods: {
    resize() {
      const { container } = this
      const { content } = this
      this.scroller.setDimensions(container.clientWidth, container.clientHeight + 60, content.offsetWidth, content.offsetHeight)
    },

    finishPullToRefresh() {
      this.scroller.finishPullToRefresh()
    },

    finishInfinite(hideSpinner) {
      this.loadingState = hideSpinner ? 2 : 0
      this.showLoading = false

      if (this.loadingState === 2) {
        setTimeout(this.resetLoadingState, 2000)
      }
    },

    triggerPullToRefresh() {
      this.scroller.triggerPullToRefresh()
    },

    scrollTo(x, y, animate) {
      this.scroller.scrollTo(x, y, animate)
    },

    scrollBy(x, y, animate) {
      this.scroller.scrollBy(x, y, animate)
    },

    touchStart(e) {
      // Don't react if initial down happens on a form element
      if (e.target.tagName.match(/input|textarea|select/i)) {
        return
      }
      this.scroller.doTouchStart(e.touches, e.timeStamp)
    },

    touchMove(e) {
      e.preventDefault()
      this.scroller.doTouchMove(e.touches, e.timeStamp)
    },

    touchEnd(e) {
      this.scroller.doTouchEnd(e.timeStamp)
    },

    mouseDown(e) {
      // Don't react if initial down happens on a form element
      if (e.target.tagName.match(/input|textarea|select/i)) {
        return
      }
      this.scroller.doTouchStart([{
        pageX: e.pageX,
        pageY: e.pageY,
      }], e.timeStamp)
      this.mousedown = true
    },

    mouseMove(e) {
      if (!this.mousedown) {
        return
      }
      this.scroller.doTouchMove([{
        pageX: e.pageX,
        pageY: e.pageY,
      }], e.timeStamp)
      this.mousedown = true
    },

    mouseUp(e) {
      if (!this.mousedown) {
        return
      }
      this.scroller.doTouchEnd(e.timeStamp)
      this.mousedown = false
    },

    // 获取位置
    getPosition() {
      const v = this.scroller.getValues()

      return {
        left: parseInt(v.left),
        top: parseInt(v.top),
      }
    },

    resetLoadingState() {
      const {
        left,
        top,
        zoom,
      } = this.scroller.getValues()
      const { container } = this
      const { content } = this

      if (top + 60 > this.content.offsetHeight - this.container.clientHeight) {
        setTimeout(() => {
          this.resetLoadingState()
        }, 1000)
      } else {
        this.loadingState = 0
      }
    },
  },
}
/* eslint-enable */
</script>

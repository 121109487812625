<template>
  <ion-app
    ref="view"
    class="view"
  >
    <div
      class="view-background"
      @click="touch"
    />
    <div :style="height === null && !hasKeyboard ? {} : {height : height}">
      <slot />
    </div>
  </ion-app>
</template>

<style lang="scss" scoped>
.view {
  display: block;
  padding: calc(env(safe-area-inset-top) + 15px) 25px 0 25px;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .view-background {
    background-color: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>

<script>
import { Device } from '@capacitor/device'
import { Keyboard } from '@capacitor/keyboard'

export default {
  data() {
    return {
      hasKeyboard: false,
      height: null,
    }
  },
  beforeDestroy() {
    try {
      Keyboard.removeAllListeners()
    } catch (e) {
      // nothing
    }
    window.onresize = null
  },
  async mounted() {
    await this.updateView()

    try {
      if (await this.isMobilePlatform()) {
        Keyboard.addListener('keyboardDidShow', async () => {
          await this.updateView()
        })

        Keyboard.addListener('keyboardDidHide', async () => {
          await this.updateView()
        })
      }
    } catch (e) {
      // nothing
    }

    window.onresize = async () => {
      await this.updateView()
    }
  },
  methods: {
    /**
     * @returns {Promise<string>}
     */
    async getPlatform() {
      return (await Device.getInfo())?.platform
    },
    /**
     * @returns {Promise<boolean>}
     */
    async isMobilePlatform() {
      return ['ios', 'android'].includes((await this.getPlatform()))
    },
    /**
     * @returns {Promise<boolean>}
     */
    async isIOSPlatform() {
      return ['ios'].includes((await this.getPlatform()))
    },
    async touch() {
      if (await this.isIOSPlatform()) {
        await Keyboard.hide()
      }
    },
    /**
     * @returns {Promise<number>}
     */
    async getInnerHeight() {
      const computed = getComputedStyle(this.$refs.view)

      // eslint-disable-next-line radix
      const padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom)
      return this.$refs.view.clientHeight - padding
    },
    async updateView() {
      if (await this.isIOSPlatform()) {
        this.height = `${(await this.getInnerHeight()) + 1}px`
      }
    },
  },
}

</script>

export const NAME_SETTINGS = 'settings'

export default [
  {
    path: '/settings',
    name: NAME_SETTINGS,
    component: () => import('@/views/pages/Settings/SettingGeneral.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]

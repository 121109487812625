import { Device } from '@capacitor/device'
import { v4 as uuidv4 } from 'uuid'

export default {
  /**
   * @param {String} email
   * @param {String} password
   * @param {Object} data
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  async login(email, password, data = {}, params = {}, headers = {}, cancelToken = null) {
    const deviceName = (await Device.getInfo()).name

    return {
      url: '/auth/login',
      method: 'POST',
      headers: { ...headers },
      params: {
        ...params,
      },
      data: {
        email,
        password,
        device_name: deviceName ?? `browser (${uuidv4()
          .toString()
          .slice(0, 8)})`,
        ...data,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} email
   * @param {Object} data
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  async passwordForgot(email, data = {}, params = {}, headers = {}, cancelToken = null) {
    return {
      url: '/account/forgot-password',
      method: 'POST',
      headers: { ...headers },
      params: {
        ...params,
      },
      data: {
        email,
        ...data,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, cancelToken: null, params: {}, url: string}}
   */
  isLogged(token, params = {}, headers = {}, cancelToken = null) {
    return {
      url: '/auth/is-logged',
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, cancelToken: null, params: {}, url: string}}
   */
  logout(token, params = {}, headers = {}, cancelToken = null) {
    return {
      url: '/auth/logout',
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
}

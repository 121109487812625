export const NAME_NOW = 'now'
export const NAME_NOW_LIST = 'now-list'
export const NAME_NOW_ONE = 'now-one'

export default [
  {
    path: '/now',
    name: NAME_NOW,
    component: () => import('@/views/pages/Now/Now.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: '/now/list',
    name: NAME_NOW_LIST,
    component: () => import('@/views/pages/Now/NowList.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: '/now/one/:crop_id',
    name: NAME_NOW_ONE,
    component: () => import('@/views/pages/Now/NowOne.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]

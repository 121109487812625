import { headersTags } from '@/libs/axios'

export default {
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {String} date
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  list(token, farmerId, date, params = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/expedition-sheet`,
      method: 'GET',
      headers: {
        [headersTags.WITH_RELATIONS]: ['plot', 'crop', 'crop_type', 'crop_packing', 'crop_grade'].join(','),
        Authorization: token,
        ...headers,
      },
      params: {
        date,
        'with-trashed': true,
        states: ['printed', 'saved'],
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  calendar(token, farmerId, params = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/expedition-sheet/calendar`,
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },

}

import axios, { headersTags } from '@/libs/axios'
import shipments from '@/apis/shipments'
import collect from 'collect.js'
import moment from '@/libs/moment'

/**
 @typedef ExpeditionSheetDays
 @property {String} date
 @property {Array<ExpeditionSheet>} sheets
 */

/**
 @typedef ExpeditionSheet
 @property {Number} id
 @property {string} uuid
 @property {string} datetime
 @property {Number} plot_id
 @property {Number} crop_id
 @property {Number} crop_type_id
 @property {Number} crop_grade_id
 @property {Number} crop_packing_id
 @property {Number} document_template_id
 @property {Number} quantity
 @property {string} state
 @property {string} send_at
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|Array<ExpeditionSheetDays>} list */
    list: null,
    /** @type {string|null} lastPull */
    lastPull: null,
    /** @type {Number} currentPage */
    currentPage: 0,
    /** @type {Number} total */
    total: 0,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isFetch(state) {
      return state.list !== null
        && state.lastPull !== null
        && state.currentPage !== 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasOne(state) {
      return state.list !== null && state.list.filter(e => (e.display === true)).length > 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasAll(state) {
      return state.list !== null && state.list.filter(e => (e.display === true)).length === state.total
    },
    /**
     * @param state
     * @returns {number}
     */
    count(state) {
      return state.list !== null ? state.list.filter(e => (e.display === true)).length : 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    has(state) {
      return state.list !== null && state.list.filter(e => (e.display === true)).length >= 1
    },
    /**
     * @param state
     * @returns {Array<ExpeditionSheetDays>}
     */
    getList(state) {
      return state.list.filter(e => (e.display === true && moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') !== moment()
        .locale('en')
        .format('YYYY-MM-DD')))
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getLastPull(state) {
      return moment(state.lastPull)
    },
    /**
     * @param state
     * @returns {Array<ExpeditionSheetDays>}
     */
    getListOrderByMonth(state) {
      const ordered = {}

      state.list
        .filter(e => (e.display === true && moment(e.date)
          .locale('en')
          .format('YYYY-MM-DD') !== moment()
          .locale('en')
          .format('YYYY-MM-DD')))
        .forEach(e => {
          const month = `${e.date.split('-')[0]}-${e.date.split('-')[1]}`
          if (typeof ordered[month] === 'undefined') {
            ordered[month] = []
          }

          ordered[month].push(e)
        })

      return ordered
    },
  },
  mutations: {
    clear(state) {
      state.list = null
      state.lastPull = null
      state.currentPage = 0
    },
    /**
     * @param state
     * @param {Array<Farmer>} list
     * @param {Number} currentPage
     * @param {Number} total
     * @returns {Promise<void>}
     */
    set(state, {
      list,
      currentPage,
      total,
    }) {
      // eslint-disable-next-line no-param-reassign
      list = list.map(e => {
        e.display = true
        return e
      })

      state.list = (state.list === null ? [...list] : [...state.list.filter(e => !(list.map(a => a.date)
        .includes(e.date))), ...list])
      state.lastPull = moment()
        .locale('en')
        .format('YYYY-MM-DD')
      state.total = total
      state.currentPage = currentPage
    },
    /**
     * @param state
     * @param {String} date
     * @param {array<ExpeditionSheet>} sheets
     */
    pullSheets(state, {
      date,
      sheets,
    }) {
      const element = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))[0]
      if (typeof element === 'undefined') return

      state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))[0].sheets = sheets
    },
    /**
     * @param state
     * @param {String} date
     */
    addManualDate(state, { date }) {
      const element = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))[0]
      if (typeof element === 'undefined') {
        state.list = [...state.list, {
          date,
          display: false,
        }]
      }
    },
  },
  actions: {
    /**
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param {boolean} clearBefore
     * @returns {Promise<boolean>}
     */
    async fetch({
      commit,
      state,
      getters,
      rootGetters,
    }, clearBefore = false) {
      /** @type {AxiosResponse<loginResponse>} response */
      const response = await (axios(shipments.calendar(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        {
          page: clearBefore ? 1 : state.currentPage + 1,
        },
        {
          [headersTags.ORDER_BY]: JSON.stringify({
            end: 'desc',
            start: 'desc',
            name: 'asc',
          }),
        },
      )))

      if (clearBefore) commit('clear')
      commit('set', {
        list: response.data?.data,
        currentPage: response.data?.last_page <= response.data?.current_page ? response.data?.last_page : response.data?.current_page,
        total: response.data?.total,
      })

      return (getters.hasAll)
    },
    /**
     * @param state
     * @param rootGetters
     * @param commit
     * @param {String} date
     * @param {boolean} force
     * @param {boolean} byPassStorageCaching
     *
     * @return {Boolean|Object}
     */
    async load({
      state,
      rootGetters,
      commit,
    }, {
      date,
      force = false,
      byPassStorageCaching = false,
    }) {
      const find = state.list === null ? null : state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))
      if (
        !force
        && find.length !== 0
        && typeof find[0].sheets !== 'undefined'
        && find[0].sheets !== null
      ) {
        return true
      }

      const fields = [
        'crop.id',
        'crop.name',
        'crop.emoji',
        'crop.color',
        'crop_type.id',
        'crop_type.name',
        'crop_type.options',
        'crop_grade.id',
        'crop_grade.name',
        'crop_grade.weight',
        'crop_packing.id',
        'crop_packing.name',
        'crop_packing.weight',
        'account.id',
        'account.name',
        'quantity',
        'external_id',
        'datetime',
        'plot.id',
        'plot.name',
        'plot.abbreviation',
        'quantity',
        'state',
        'send_at',
        'is_valid',
        'pdf_url',
        'xml_url',
        'json_url',
      ]
      const response = await (axios(shipments.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        moment(date)
          .locale('en')
          .format('YYYY-MM-DD'),
        {},
        {
          [headersTags.ORDER_BY]: JSON.stringify({
            datetime: 'desc',
          }),
          [headersTags.FIELDS]: JSON.stringify(fields),
          [headersTags.NO_PAGINATE]: true,
        },
      )))

      if (byPassStorageCaching) {
        commit('addManualDate', {
          date,
        })
      }

      commit('pullSheets', {
        date,
        sheets: response.data?.data,
      })

      return response.data?.data.length >= 1
    },
    /**
     * @param state
     * @param {String} date
     */
    has({
      state,
    }, date) {
      if (state.list === null) return null

      const find = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))
      return (find.length >= 1)
    },
    /**
     * @param state
     * @param {String} date
     */
    find({
      state,
    }, date) {
      if (state.list === null) return null

      const find = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))
      if (find.length >= 1) return find[0]

      return null
    },
    /**
     * @param state
     * @param {String} date
     * @param {null|Number} crop
     * @returns {null|Function}
     */
    getStats({
      state,
    }, {
      date,
      crop = null,
    }) {
      if (state.list === null) return null
      const find = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))
      if (find.length !== 1) return null

      let stats = {}
      let query = JSON.parse(JSON.stringify(find[0].sheets))
        .filter(e => e.is_valid)

      if (crop !== null) {
        query = query.filter(e => e.crop.id.toString() === crop.toString())
      }

      query.forEach(({
        // eslint-disable-next-line no-shadow
        crop,
        crop_type,
        crop_packing,
        crop_grade,
        plot,
        quantity,
        account,
      }) => {
        if (typeof stats[crop.id] === 'undefined') stats[crop.id] = crop
        if (typeof stats[crop.id].types === 'undefined') stats[crop.id].types = {}
        if (typeof stats[crop.id].packings === 'undefined') stats[crop.id].packings = {}
        if (typeof stats[crop.id].grades === 'undefined') stats[crop.id].grades = {}
        if (typeof stats[crop.id].plots === 'undefined') stats[crop.id].plots = {}
        if (typeof stats[crop.id].options === 'undefined') stats[crop.id].options = crop_type.options
        if (typeof stats[crop.id].accounts === 'undefined') stats[crop.id].accounts = {}
        if (typeof stats[crop.id].total_weight === 'undefined') stats[crop.id].total_weight = 0
        if (typeof stats[crop.id].quantity === 'undefined') stats[crop.id].quantity = 0

        // PER TYPES
        if (typeof stats[crop.id].types[crop_type.id] === 'undefined') {
          stats[crop.id].types[crop_type.id] = collect(crop_type)
            .filter((value, key) => ['id', 'name'].includes(key)).items
          stats[crop.id].types[crop_type.id].weight = []
          stats[crop.id].types[crop_type.id].quantities = []
        }
        stats[crop.id].types[crop_type.id].weight.push(stats[crop.id].options.includes('packings') ? parseFloat(crop_packing.weight) * quantity : parseFloat(crop_grade.weight) * quantity)
        stats[crop.id].types[crop_type.id].quantities.push(quantity)

        // PER PACKING
        // eslint-disable-next-line camelcase
        if (crop_packing !== null) {
          if (typeof stats[crop.id].packings[crop_packing.id] === 'undefined') {
            stats[crop.id].packings[crop_packing.id] = collect(crop_packing)
              .filter((value, key) => ['id', 'name'].includes(key)).items
            stats[crop.id].packings[crop_packing.id].weight = []
            stats[crop.id].packings[crop_packing.id].quantities = []
          }
          stats[crop.id].packings[crop_packing.id].weight.push(parseFloat(crop_packing.weight) * quantity)
          stats[crop.id].packings[crop_packing.id].quantities.push(quantity)
        }

        // PER GRADES
        // eslint-disable-next-line camelcase
        if (crop_grade !== null) {
          if (typeof stats[crop.id].grades[crop_grade.id] === 'undefined') {
            stats[crop.id].grades[crop_grade.id] = collect(crop_grade)
              .filter((value, key) => ['id', 'name'].includes(key)).items
            stats[crop.id].grades[crop_grade.id].weight = []
            stats[crop.id].grades[crop_grade.id].quantities = []
          }
          stats[crop.id].grades[crop_grade.id].weight.push(parseFloat(crop_packing.weight) * quantity)
          stats[crop.id].grades[crop_grade.id].quantities.push(quantity)
        }

        // PER PLOTS
        // eslint-disable-next-line camelcase
        if (plot !== null) {
          if (typeof stats[crop.id].plots[plot.id] === 'undefined') {
            stats[crop.id].plots[plot.id] = collect(plot)
              .filter((value, key) => ['id', 'name', 'abbreviation'].includes(key)).items
            stats[crop.id].plots[plot.id].weight = []
            stats[crop.id].plots[plot.id].quantities = []
          }
          stats[crop.id].plots[plot.id].weight.push(parseFloat(crop_packing.weight) * quantity)
          stats[crop.id].plots[plot.id].quantities.push(quantity)
        }

        // PER ACCOUNT
        // eslint-disable-next-line camelcase
        if (account !== null) {
          if (typeof stats[crop.id].accounts[account.id] === 'undefined') {
            stats[crop.id].accounts[account.id] = collect(account)
              .filter((value, key) => ['id', 'name'].includes(key)).items
            stats[crop.id].accounts[account.id].weight = []
            stats[crop.id].accounts[account.id].quantities = []
          }
          stats[crop.id].accounts[account.id].weight.push(parseFloat(crop_packing.weight) * quantity)
          stats[crop.id].accounts[account.id].quantities.push(quantity)
        }
      })

      stats = collect(stats)
        .map(value => {
          collect(value.types)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              value.total_weight = parseFloat(value.total_weight) + parseFloat(v.total_weight)
              // eslint-disable-next-line no-param-reassign
              value.quantity = parseFloat(value.quantity) + parseFloat(v.quantity)
            })
          collect(value.packings)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
            })
          collect(value.grades)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
            })
          collect(value.plots)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
            })
          collect(value.accounts)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
            })

          return value
        }).items

      return stats
    },
    /**
     * @param state
     * @param {String} date
     * @param {null|Number|String} crop
     * @returns {Array<ExpeditionSheet>}
     */
    getSheets({
      state,
    }, {
      date,
      crop = null,
    }) {
      if (state.list === null) return null

      const find = state.list.filter(e => moment(e.date)
        .locale('en')
        .format('YYYY-MM-DD') === moment(date)
        .locale('en')
        .format('YYYY-MM-DD'))
      if (find.length !== 1) return null

      let query = find[0].sheets

      if (crop !== null) {
        query = query.filter(e => e.crop.id.toString() === crop.toString())
      }

      return query
    },
  },
}

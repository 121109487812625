export const NAME_RESUME = 'resume'
export const NAME_RESUME_CAMPAIGNS = 'resume-campaigns'
export const NAME_RESUME_ADD_CAMPAIGN = 'resume-add-campaign'
export const NAME_RESUME_EDIT_CAMPAIGN = 'resume-edit-campaign'
export const NAME_RESUME_ONE_CAMPAIGN = 'resume-one-campaign'
export const NAME_RESUME_ONE_CAMPAIGN_CROP = 'resume-one-campaign-crop'

export default [
  {
    path: '/resume',
    name: NAME_RESUME,
    meta: {
      needUserLogged: true,
    },
    redirect: {
      name: NAME_RESUME_CAMPAIGNS,
    },
  },
  {
    path: '/resume/campaigns',
    name: NAME_RESUME_CAMPAIGNS,
    component: () => import('@/views/pages/Campaigns/CampaignList.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: '/resume/campaigns/add',
    name: NAME_RESUME_ADD_CAMPAIGN,
    component: () => import('@/views/pages/Campaigns/CampaignAdd.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: '/resume/campaigns/edit/:campaign_id',
    name: NAME_RESUME_EDIT_CAMPAIGN,
    component: () => import('@/views/pages/Campaigns/CampaignEdit.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: '/resume/campaigns/:campaign_id',
    name: NAME_RESUME_ONE_CAMPAIGN,
    component: () => import('@/views/pages/Campaigns/CampaignOne.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: '/resume/campaigns/:campaign_id/crops/:crop_id',
    name: NAME_RESUME_ONE_CAMPAIGN_CROP,
    component: () => import('@/views/pages/Campaigns/CampaignOneCrop.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]

import { isLogged } from '@/router/utils'
import { NAME_INIT } from '@/router/routes/auth'

export const NAME_ROOT = 'root'
export const NAME_LOADING = 'loading'

export default [
  {
    path: '/',
    name: NAME_ROOT,
    redirect: () => {
      if (!isLogged()) {
        return { name: NAME_INIT }
      }

      return { name: NAME_LOADING }
    },
  },
  {
    path: '/loading',
    name: NAME_LOADING,
    component: () => import('@/views/Loading.vue'),
    meta: {
      layout: 'blank',
      needUserLogged: true,
    },
  },
]

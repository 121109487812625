import axios, { hasSyncHeader, headersTags } from '@/libs/axios'

import moment from '@/libs/moment'
import shipments from '@/apis/shipments'
import collect from 'collect.js'

/**
 @typedef ExpeditionSheet
 @property {Number} id
 @property {string} uuid
 @property {string} datetime
 @property {Number} plot_id
 @property {Number} crop_id
 @property {Number} crop_type_id
 @property {Number} crop_grade_id
 @property {Number} crop_packing_id
 @property {Number} document_template_id
 @property {Number} quantity
 @property {string} state
 @property {string} send_at
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|Array<ExpeditionSheet>} list */
    list: null,
    /** @type {string|null} lastPull */
    lastPull: null,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isFetch(state) {
      return state.list !== null
        && state.lastPull !== null
    },
    /**
     * @param state
     * @returns {Array<ExpeditionSheet>}
     */
    getList(state) {
      return state.list
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getLastPull(state) {
      return state.lastPull ? moment(state.lastPull) : null
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getStats(state) {
      if (state.list === null) return null

      let stats = {}
      state.list.filter(sheet => sheet.is_valid === true)
        .forEach(({
          crop,
          crop_type,
          crop_packing,
          crop_grade,
          quantity,
          plot,
          account,
        }) => {
          if (typeof stats[crop.id] === 'undefined') stats[crop.id] = crop
          if (typeof stats[crop.id].types === 'undefined') stats[crop.id].types = {}
          if (typeof stats[crop.id].packings === 'undefined') stats[crop.id].packings = {}
          if (typeof stats[crop.id].grades === 'undefined') stats[crop.id].grades = {}
          if (typeof stats[crop.id].plots === 'undefined') stats[crop.id].plots = {}
          if (typeof stats[crop.id].options === 'undefined') stats[crop.id].options = crop_type.options
          if (typeof stats[crop.id].accounts === 'undefined') stats[crop.id].accounts = {}
          if (typeof stats[crop.id].total_weight === 'undefined') stats[crop.id].total_weight = 0
          if (typeof stats[crop.id].quantity === 'undefined') stats[crop.id].quantity = 0

          // PER TYPES
          if (typeof stats[crop.id].types[crop_type.id] === 'undefined') {
            stats[crop.id].types[crop_type.id] = collect(crop_type)
              .filter((value, key) => ['id', 'name'].includes(key)).items
            stats[crop.id].types[crop_type.id].weight = []
            stats[crop.id].types[crop_type.id].quantities = []
          }
          stats[crop.id].types[crop_type.id].weight.push(stats[crop.id].options.includes('packings') ? parseFloat(crop_packing.weight) * quantity : parseFloat(crop_grade.weight) * quantity)
          stats[crop.id].types[crop_type.id].quantities.push(quantity)

          // PER PACKING
          // eslint-disable-next-line camelcase
          if (crop_packing !== null) {
            if (typeof stats[crop.id].packings[crop_packing.id] === 'undefined') {
              stats[crop.id].packings[crop_packing.id] = collect(crop_packing)
                .filter((value, key) => ['id', 'name'].includes(key)).items
              stats[crop.id].packings[crop_packing.id].weight = []
              stats[crop.id].packings[crop_packing.id].quantities = []
            }
            stats[crop.id].packings[crop_packing.id].weight.push(parseFloat(crop_packing.weight) * quantity)
            stats[crop.id].packings[crop_packing.id].quantities.push(quantity)
          }

          // PER GRADES
          // eslint-disable-next-line camelcase
          if (crop_grade !== null) {
            if (typeof stats[crop.id].grades[crop_grade.id] === 'undefined') {
              stats[crop.id].grades[crop_grade.id] = collect(crop_grade)
                .filter((value, key) => ['id', 'name'].includes(key)).items
              stats[crop.id].grades[crop_grade.id].weight = []
              stats[crop.id].grades[crop_grade.id].quantities = []
            }
            stats[crop.id].grades[crop_grade.id].weight.push(parseFloat(crop_packing.weight) * quantity)
            stats[crop.id].grades[crop_grade.id].quantities.push(quantity)
          }

          // PER PLOTS
          // eslint-disable-next-line camelcase
          if (plot !== null) {
            if (typeof stats[crop.id].plots[plot.id] === 'undefined') {
              stats[crop.id].plots[plot.id] = collect(plot)
                .filter((value, key) => ['id', 'name', 'abbreviation'].includes(key)).items
              stats[crop.id].plots[plot.id].weight = []
              stats[crop.id].plots[plot.id].quantities = []
            }
            stats[crop.id].plots[plot.id].weight.push(parseFloat(crop_packing.weight) * quantity)
            stats[crop.id].plots[plot.id].quantities.push(quantity)
          }

          // PER ACCOUNT
          // eslint-disable-next-line camelcase
          if (account !== null) {
            if (typeof stats[crop.id].accounts[account.id] === 'undefined') {
              stats[crop.id].accounts[account.id] = collect(account)
                .filter((value, key) => ['id', 'name'].includes(key)).items
              stats[crop.id].accounts[account.id].weight = []
              stats[crop.id].accounts[account.id].quantities = []
            }
            stats[crop.id].accounts[account.id].weight.push(parseFloat(crop_packing.weight) * quantity)
            stats[crop.id].accounts[account.id].quantities.push(quantity)
          }
        })

      stats = collect(stats)
        .map(value => {
          collect(value.types)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              value.total_weight = parseFloat(value.total_weight) + parseFloat(v.total_weight)
              // eslint-disable-next-line no-param-reassign
              value.quantity = parseFloat(value.quantity) + parseFloat(v.quantity)
            })
          collect(value.packings)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
            })
          collect(value.grades)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
            })
          collect(value.plots)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
            })
          collect(value.accounts)
            .each(v => {
              // eslint-disable-next-line no-param-reassign
              v.total_weight = v.weight.reduce((a, b) => a + b)
              // eslint-disable-next-line no-param-reassign
              v.quantity = v.quantities.reduce((a, b) => a + b)
            })

          return value
        }).items

      return stats
    },
    /**
     * @param state
     * @returns {number}
     */
    count(state) {
      return state.list !== null ? state.list.length : 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    has(state) {
      return state.list !== null && state.list.filter(sheet => sheet.is_valid === true).length >= 1
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasWithTrashed(state) {
      return state.list !== null && state.list.length >= 1
    },
  },
  mutations: {
    async clear(state) {
      state.list = null
      state.lastPull = null
    },
    /**
     * @param state
     * @param {Array<ExpeditionSheet>} list
     *
     * @returns {Promise<void>}
     */
    async set(state, list) {
      state.list = list
      state.lastPull = moment()
        .locale('en')
        .format('YYYY-MM-DD HH:mm:ss')
    },
    /**
     * @param state
     * @param {Array<ExpeditionSheet>} completeList
     *
     * @returns {Promise<void>}
     */
    async update(state, completeList) {
      state.list = [...completeList, ...state.list]
      state.lastPull = moment()
        .locale('en')
        .format('YYYY-MM-DD HH:mm:ss')
    },
  },
  actions: {
    /**
     * @param commit
     * @param getters
     * @param state
     * @param rootGetters
     * @returns {Promise<boolean>}
     */
    async fetch({
      commit,
      getters,
      state,
      rootGetters,
    }) {
      const fields = [
        'crop.id',
        'crop.name',
        'crop.emoji',
        'crop.color',
        'crop_type.id',
        'crop_type.name',
        'crop_type.options',
        'crop_grade.id',
        'crop_grade.name',
        'crop_grade.weight',
        'crop_packing.id',
        'crop_packing.name',
        'crop_packing.weight',,
        'account.id',
        'account.name',
        'quantity',
        'external_id',
        'datetime',
        'plot.id',
        'plot.name',
        'plot.abbreviation',
        'quantity',
        'state',
        'send_at',
        'is_valid',
        'pdf_url',
        'xml_url',
        'json_url',
      ]

      const pullAll = async () => {
        /** @type {AxiosResponse<loginResponse>} response */
        const response = await (axios(await (shipments.list(
          rootGetters['auth/getToken'],
          rootGetters['farmers/getDefaultFarmer']?.id,
          moment()
            .locale('en')
            .format('YYYY-MM-DD'),
          {},
          {
            [headersTags.NO_PAGINATE]: true,
            [headersTags.FIELDS]: JSON.stringify(fields),
          },
        ))))

        commit('clear')
        commit('set', response.data.data)
      }
      const sync = async () => {
        const response = await (axios(await (shipments.list(
          rootGetters['auth/getToken'],
          rootGetters['farmers/getDefaultFarmer']?.id,
          moment()
            .locale('en')
            .format('YYYY-MM-DD'),
          {},
          {
            [headersTags.NO_PAGINATE]: true,
            [headersTags.FIELDS]: JSON.stringify(fields),
            [headersTags.SYNC_DATE]: moment(state.lastPull)
              .locale('en')
              .format('YYYY-MM-DD'),
            [headersTags.SYNC_IDS]: Object.values(state.list)
              .map(data => data.id),
          },
        ))))

        if (!hasSyncHeader(response?.headers)) {
          commit('set', response.data.data)
        } else {
          commit('update', response.data.data)
        }
      }

      if (getters.isFetch) {
        await sync()
      } else {
        await pullAll()
      }

      return true
    },
  },
}

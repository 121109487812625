import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  timeout: 10000,
})

export const headersTags = {
  PAGINATE: 'X-Paginate',
  NO_PAGINATE: 'X-No-Paginate',

  ORDER_BY: 'X-Order-By',

  FIELDS: 'X-Fields',
  WITH_RELATIONS: 'X-With-Relations',

  SYNC_DATE: 'X-Sync-Date',
  SYNC_IDS: 'X-Sync-Ids',
  SYNC_IS_POSSIBLE: 'X-Sync-Is-Possible',
}

/**
 * @param {Object} headers
 * @returns {number|boolean}
 */
// eslint-disable-next-line func-names
export const hasSyncHeader = function (headers = {}) {
  // eslint-disable-next-line no-param-reassign
  headers = Object.fromEntries(
    Object.entries(headers)
      .map(entry => {
        // eslint-disable-next-line no-param-reassign
        entry[0] = entry[0].toLowerCase()
        return entry
      }),
  )

  // eslint-disable-next-line no-return-assign
  return typeof headers[headersTags.SYNC_IS_POSSIBLE.toLowerCase()] !== 'undefined'
    // eslint-disable-next-line no-param-reassign,no-bitwise
    ? (headers[headersTags.SYNC_IS_POSSIBLE.toLowerCase()] === 1
      | (headers[headersTags.SYNC_IS_POSSIBLE.toLowerCase()] === '1')
      | (headers[headersTags.SYNC_IS_POSSIBLE.toLowerCase()] === 'true'))
    : false
}

export default axiosIns

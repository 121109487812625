import Scroller from './Scroller.vue'
import ScrollWrap from './Wrapper.vue'

function install(Vue) {
  if (install.installed) return
  install.installed = true
  Vue.component('scroller', ScrollWrap)
}

const VueScroller = {
  install,
  Scroller,
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueScroller)
}

export default VueScroller

import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import campaigns from '@/store/campaigns'
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import farmers from './farmers'
import shipmentsNow from './shipments/now'
import shipmentsHistory from './shipments/history'
import server from './server'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    farmers,
    campaigns,
    shipmentsNow,
    shipmentsHistory,
    server,
  },
  strict: process.env.DEV,
})

// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

export const LAYOUT_TYPES = [
  'app', 'blank',
]

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'Agri+', // Will update name in navigation menu (Branding)
    appWebSite: 'https://agriplus.io', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/agriplus-logo.svg'), // Will update logo in navigation menu (Branding)
  },
  layout: {
    skin: 'light',
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: LAYOUT_TYPES[1],
  },
}

export const NAME_FARMERS = 'farmers'

export default [
  {
    path: '/farmers',
    name: NAME_FARMERS,
    component: () => import('@/views/pages/Farmers/FarmersList.vue'),
    meta: {
      layout: 'blank',
      needUserLogged: true,
    },
  },
]

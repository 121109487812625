import auth from '@/apis/auth'
import axios from '@/libs/axios'
import moment from '@/libs/moment'
import user from '@/apis/user'
import { Preferences } from '@capacitor/preferences'

export const LOCALSTORAGE_KEY = 'auth'
export const LOCALSTORAGE_LAST_EMAIL_KEY = 'last_auth_email'

/**
 @typedef loginResponse
 @property {string} token
 @property {string} device_token
 @property {string} created_at
 @property {string} updated_at
 @property {string} expire_at
 */
/**
 @typedef userProfile
 @property {string} avatar
 @property {string} firstname
 @property {string} lastname
 @property {string} email
 @property {string} created_at
 @property {string} updated_at
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|string} token */
    token: null,
    /** @type {null|string} deviceToken */
    deviceToken: null,
    /** @type {null|string} createdAt */
    createdAt: null,
    /** @type {null|string} updatedAt */
    updatedAt: null,
    /** @type {null|string} expireAt */
    expireAt: null,
    /** @type {null|userProfile} user */
    user: null,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isLogged(state) {
      return state.token != null
        && state.deviceToken != null
        && state.createdAt != null
        && state.updatedAt != null
        && state.expireAt != null
    },
    /**
     * @param state
     * @returns {null|String}
     */
    getToken(state) {
      return state.token
    },
    /**
     * @param state
     * @returns {null|String}
     */
    getDeviceToken(state) {
      return state.deviceToken
    },
    /**
     * @param state
     * @returns {null|userProfile}
     */
    getUser(state) {
      return state.user
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getCreatedAt(state) {
      return moment(state.createdAt)
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getUpdateAt(state) {
      return moment(state.updatedAt)
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getExpireAt(state) {
      return moment(state.expireAt)
    },
  },
  mutations: {
    async clear(state) {
      state.token = null
      state.createdAt = null
      state.updatedAt = null
      state.expireAt = null
      state.user = null

      await Preferences.remove({ key: LOCALSTORAGE_KEY })
    },
    /**
     * @param state
     * @param {string} deviceToken
     * @param {string} token
     * @param {moment} createdAt
     * @param {moment} updatedAt
     * @param {moment} expireAt
     * @returns {Promise<void>}
     */
    async setAll(state, {
      deviceToken,
      token,
      createdAt,
      updatedAt,
      expireAt,
    }) {
      state.token = token
      state.deviceToken = deviceToken
      state.createdAt = moment(createdAt)
        .locale('en')
        .format('YYYY-MM-DD HH:mm:ss')
      state.updatedAt = moment(updatedAt)
        .locale('en')
        .format('YYYY-MM-DD HH:mm:ss')
      state.expireAt = moment(expireAt)
        .locale('en')
        .format('YYYY-MM-DD HH:mm:ss')

      await Preferences.set({
        key: LOCALSTORAGE_KEY,
        value: JSON.stringify({
          token: state.token,
          deviceToken: state.deviceToken,
          createdAt: state.createdAt,
          updatedAt: state.updatedAt,
          expireAt: state.expireAt,
        }),
      })
    },
    /**
     * @param state
     * @param {userProfile} data
     */
    async setUserProfile(state, data) {
      state.user = data

      await Preferences.set({
        key: LOCALSTORAGE_LAST_EMAIL_KEY,
        value: state.user.email ?? '',
      })
    },
  },
  actions: {
    /**
     * @param commit
     * @param state
     * @param email
     * @param password
     * @returns {Promise<boolean>}
     */
    async login({
      commit,
      state,
    }, {
      email,
      password,
    }) {
      const data = (state.deviceToken !== null ? {
        device_token: state.deviceToken,
      } : {})

      /** @type {AxiosResponse<loginResponse>} response */
      const response = await (axios(await (auth.login(email, password, data))))

      await commit('clear')
      await commit('setAll', {
        token: response.data.token,
        deviceToken: response.data.device_token,
        createdAt: response.data.created_at,
        updatedAt: response.data.updated_at,
        expireAt: response.data.expire_at,
      })

      return true
    },
    /**
     * @param getters
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchUserProfile({
      getters,
      commit,
    }) {
      const response = (await axios(user.profile(getters.getToken)))
      commit('setUserProfile', response.data)
    },
    /**
     * @param state
     * @param {String} email
     * @returns {Promise<boolean>}
     */
    // eslint-disable-next-line no-empty-pattern
    async passwordForgot({}, {
      email,
    }) {
      /** @type {AxiosResponse<loginResponse>} response */
      const response = await (axios(await (auth.passwordForgot(email))))
      return response.status === 200
    },
    /**
     * @param getters
     * @param commit
     * @returns {Promise<boolean>}
     */
    async logout({
      getters,
      commit,
    }) {
      const response = (await axios(auth.logout(getters.getToken)))

      if (response.status === 200) {
        await commit('clear')
        return true
      }

      return false
    },
    /**
     * @param dispatch
     * @param commit
     * @param state
     * @returns {Promise<void>}
     */
    async restore({
      dispatch,
      commit,
      state,
    }) {
      const a = await Preferences.get({ key: LOCALSTORAGE_KEY })
      if (a.value === null) {
        return
      }
      const c = JSON.parse(a.value)

      state.token = c.token ?? null
      state.deviceToken = c.deviceToken ?? null
      state.createdAt = c.createdAt ?? null
      state.updatedAt = c.updatedAt ?? null
      state.expireAt = c.expireAt ?? null

      try {
        const check = await (axios(auth.isLogged(state.token)))
        if (!(check?.data?.state)) throw new Error('Token is invalid')
      } catch (e) {
        await commit('clear')
        return
      }

      await dispatch('fetchUserProfile')
    },
  },
}

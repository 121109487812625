import config from '@/config.json'
import { Preferences } from '@capacitor/preferences'

export const LOCALSTORAGE_KEY = 'server'

export default {
  namespaced: true,
  state: {
    server: null,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isDefine: state => state.server !== null,
    /**
     * @param state
     * @returns {string}
     */
    getServer: state => state.server?.trim(),
    /**
     * @param state
     * @returns {string}
     */
    getCleanServer: state => {
      const START = '@@@@'
      const END = '###'
      return (START + state.server?.trim() + END)
        .replace('http://', '')
        .replace('https://', '')
        .replace(`${START}api.`, START)
        .replace(START, '')
        .replace(END, '')
        .trim()
    },
    /**
     * @param state
     * @returns {string}
     */
    getUrl: state => (`${state.server}/${config['api-version']}`).trim(),
  },
  mutations: {
    /**
     * @param state
     * @param {String} val
     * @returns {Promise<void>}
     */
    async setServer(state, val) {
      let value = val

      if (val.charAt(val.length - 1) === '/') {
        value = val.slice(0, -1)
      }

      state.server = value.trim()

      await Preferences.set({
        key: LOCALSTORAGE_KEY,
        value: state.server,
      })
    },
    /**
     * @param state
     * @returns {Promise<void>}
     */
    async resetServer(state) {
      state.server = null

      await Preferences.set({
        key: LOCALSTORAGE_KEY,
        value: state.server,
      })
    },
  },
  actions: {
    /**
     * @param state
     * @returns {Promise<void>}
     */
    async restore({ state }) {
      const r = await Preferences.get({ key: LOCALSTORAGE_KEY })
      state.server = r.value
    },
  },
}
